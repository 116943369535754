import mellonLogo from '~/ui/assets/icons/logo-news.svg?url';
import { type ArticleCard } from '~/v1/_types/ArticleCard';
import { type NestedArticleCard } from '~/v1/_types/NestedArticleCard';
import { formatDate } from '~/v1/utils/format';

import { type NewsCardType } from './news.interface';
import { getArticleCTA } from '../article/article.utils';

export const mapNewsCard = (card: ArticleCard | NestedArticleCard): NewsCardType => {
  const cta = getArticleCTA(card);

  return {
    logo: card.publisherLogo?.src
      ? {
          src: card.publisherLogo.src,
          title: 'Publisher logo',
          description: '',
        }
      : {
          ...mellonLogo,
          title: 'Mellon logo',
          description: '',
        },
    description: card.title,
    ...cta,
    ctaUrl: card.externalNewsUrl || cta.ctaUrl,
    eyebrow: card.hideArticleDate ? '' : formatDate(card.articleDate),
    isExternalNews: !!card.externalNewsUrl,
  };
};
